.react-vis-magic-css-import-rule {
  display: inherit;
}
.rv-treemap {
  font-size: 12px;
  position: relative;
}
.rv-treemap__leaf {
  overflow: hidden;
  position: absolute;
}
.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}
.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
}
.rv-xy-plot {
  color: #c3c3c3;
  position: relative;
}
.rv-xy-plot canvas {
  pointer-events: none;
}
.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute;
}
.rv-xy-plot__inner {
  display: block;
}
.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9;
}
.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9;
}
.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px;
}
.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px;
}
.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9;
}
.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9;
}
.rv-xy-plot__series,
.rv-xy-plot__series path {
  pointer-events: all;
}
.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
}
.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none;
}
.rv-crosshair__line {
  background: #47d3d9;
  width: 1px;
}
.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0;
}
.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.rv-crosshair__inner--left {
  right: 4px;
}
.rv-crosshair__inner--right {
  left: 4px;
}
.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap;
}
.rv-crosshair__item {
  white-space: nowrap;
}
.rv-hint {
  position: absolute;
  pointer-events: none;
}
.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap;
}
.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
}
.rv-discrete-color-legend.horizontal {
  white-space: nowrap;
}
.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px;
}
.rv-discrete-color-legend-item.horizontal {
  display: inline-block;
}
.rv-discrete-color-legend-item.horizontal
  .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block;
}
.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
}
.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px;
}
.rv-discrete-color-legend-item__title {
  margin-left: 10px;
}
.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8;
}
.rv-discrete-color-legend-item.clickable {
  cursor: pointer;
}
.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9;
}
.rv-search-wrapper {
  display: flex;
  flex-direction: column;
}
.rv-search-wrapper__form {
  flex: 0;
}
.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0;
}
.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto;
}
.rv-continuous-color-legend {
  font-size: 12px;
}
.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.rv-continuous-size-legend {
  font-size: 12px;
}
.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%;
}
.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom;
}
.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%;
}
.rv-legend-titles {
  height: 16px;
  position: relative;
}
.rv-legend-titles__left,
.rv-legend-titles__right,
.rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
}
.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%;
}
.rv-legend-titles__right {
  right: 0;
}
.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none;
}

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #e8e9ea;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e8e9ea 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #227c9d 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #227c9d 0 -3px 0 inset;
  background-color: #227c9d;
  color: white;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(45, 35, 66, 0.3) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #e8e9ea 0 3px 7px inset;
  background-color: #227c9d;
  transform: translateY(2px);
  color: white;
}

.button-40 {
  align-items: center;
  appearance: none;
  background-color: #ffcd00;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ffcd00 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-40:focus {
  box-shadow: #ffcd00 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ffcd00 0 -3px 0 inset;
  background-color: #ffcd00;
}

.button-40:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(45, 35, 66, 0.3) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-40:active {
  box-shadow: #ffcd00 0 3px 7px inset;
  background-color: #ffcd00;
  transform: translateY(2px);
}

#placementbut {
  align-items: center;
  appearance: none;
  background-color: #e8e9ea;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e8e9ea 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 20px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 15px;
  margin-bottom: 30px;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

#conceptos {
  text-decoration: none;
  list-style-type: none;

  margin: 20px;
  background-color: #ffcd00;
  align-items: center;
  appearance: none;
  background-color: #ffcd00;
  border-radius: 20px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ffcd00 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  font-family: "JetBrains Mono", monospace;
  height: 150px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

#conceptos:active {
  box-shadow: #f45b69 0 3px 7px inset;
  background-color: #f45b69;
  transform: translateY(2px);
}

#imgplace {
  width: 20%;
  float: left;
  height: 100%;
  background-color: #227c9d;
  border-radius: 0 12rem 12rem 0;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  text-align: center;
  display: block;
}

#centerimg {
  text-align: center;
  display: flex;
  margin-top: 70%;
  margin-right: 10%;
  justify-content: center;
  vertical-align: middle;
}

#lecturetitle {
  width: 65%;
  height: 100%;
  margin-left: 5px;
  margin-bottom: 5px;
  font-family: "JetBrains Mono", monospace;
}

#actualtitle {
  border-bottom: 3px solid black;
  font-weight: bold;
}

#conceptlist {
  display: inline;
  list-style: none;
  padding: 1px;
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#conceptlist2 {
  display: inline;
  list-style: none;
  padding: 1px;
  margin: 5px;
  margin-top: 10px;
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
}

[data-tooltip]:hover:before {
  opacity: 1;
}

.data-tooltip:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
}

.data-tooltip:hover:before {
  opacity: 1;
}

.hide {
  display: none;
}
.placement {
  align-items: center;
  display: flex;
  justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

#nav {
  text-align: center;
}

#filterlist {
  list-style: none;
}

ul#filterlist li {
  display: inline;
}

#topicbutton {
  display: inline;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}

a {
  text-decoration: none;
}

ol {
  list-style: none;
  text-decoration: none;
}

#buttonld {
  padding: 10px;
  background-color: grey;
  padding: 5px;
  text-align: justify;
  width: 100%;
  font-size: 20px;
}

#leaderboard {
  width: 100%;
}

#chartplace {
  width: 50%;
  margin: auto;
}

/* Create three unequal columns that floats next to each other */
.column {
  position: flex;
  float: left;
}

/* Left and right column */
.column.left {
  width: 38%;
  float: left;
  position: absolute;
  top: 178px;
  left: 1%;
}

/* Middle column */
.column.middle {
  width: 61%;
  background-color: white;
  text-align: center;
  margin-left: 10px;
  margin-right: auto;
  display: block;
  border: thin solid lightgrey;
  border-radius: 5px;
  float: right;
}

.secondcol {
  float: left;
  padding: 10px;
}

/* Left and right column */
.secondcol.first {
  width: 38%;
  text-align: center;
  float: left;
}

/* Middle column */
.secondcol.second {
  width: 61%;
  text-align: center;
  margin-left: 10px;
  margin-right: auto;
  display: block;
  float: right;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column.left,
  .column.middle {
    width: 100%;
  }

  .secondcol.first,
  .secondcol.first {
    width: 100%;
  }
}

Chart {
  width: 50%;
  margin: auto;
}
