@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-family: "source sans pro", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-background text-txt_primary;
  }
}
